import ResultsProgress from 'components/ResultsProgress';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';
import styled from 'styled-components';
import { tablet, useQuery } from 'styles/breakpoints';
import { usePageView, useQuizData } from 'utils/hooks';
import { normalizeStates } from 'utils/localization';
import Chart from 'assets/icons/results-summary.svg';
import Logo from 'assets/icons/logo.svg';
import { DynamicImage, PrimaryButton } from 'components';
import { useRouter } from 'apis/history';

const ResultsProgram: FC = () => {
  const [loading, setLoading] = useState(false);
  const data = useQuizData('resultsProgram');
  const user = useSelector((state: AppState) => state.user);
  const { goToResultsHabits } = useRouter();
  const { isTablet } = useQuery();
  const handleClick = () => {
    setLoading(true);
    goToResultsHabits();
  };

  usePageView({
    client_code: user.code,
    city: encodeURIComponent(
      user.geolocation?.city?.toLowerCase().replace(/[^a-z0-9]/g, '') || '',
    ),
    country: user.geolocation?.iso_country?.toLowerCase(),
    state: normalizeStates(
      user.geolocation?.iso_country || '',
      user.geolocation?.iso_state || '',
    ),
    gender: user.quiz_answers?.gender ? user.quiz_answers?.gender[0] : null,
    email: user.user?.email,
  });

  return (
    <Container>
      <Inner>
        <LogoStyled />
        <ResultsProgress
          currentStep={data?.currentStep}
          totalSteps={data?.totalSteps}
        />
        <Card>
          <Title>{data?.title}</Title>
          <Subtitle dangerouslySetInnerHTML={{ __html: data?.subtitle }} />
          <StyledImg src={isTablet ? data?.imgMob : data?.imgDes} alt="" />
          {/* <Bottom>
            <BottomSection>
              <Color withLC />
              <BottomText dangerouslySetInnerHTML={{ __html: data?.with }} />
            </BottomSection>
            <BottomSection>
              <Color />
              <BottomText dangerouslySetInnerHTML={{ __html: data?.without }} />
            </BottomSection>
          </Bottom> */}
          <StyledButton onClick={handleClick} loading={loading}>
            {data?.buttonText}
          </StyledButton>
        </Card>
      </Inner>
    </Container>
  );
};

export default ResultsProgram;

const Container = styled.section`
  background: #ffe7de;
  padding: 2rem 1rem;
  @media ${tablet} {
    padding: 0 1rem 2rem;
  }
  min-height: 100vh;
`;

const Inner = styled.section`
  max-width: 31.25rem;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const LogoStyled = styled(Logo)`
  margin: 1.25rem auto;
`;

const Card = styled.section`
  display: flex;
  width: 100%;
  padding: 1.5rem 1rem;
  flex-direction: column;
  align-items: center;
  border-radius: 1rem;
  background: #fff;
  box-shadow: 0px 4.935px 16px 0px rgba(0, 0, 0, 0.08);
  margin-top: 1.5rem;
  @media ${tablet} {
    margin-top: 2rem;
  }
`;

const Title = styled.h1`
  color: #28293d;
  text-align: center;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 800;
  line-height: 130%;
  letter-spacing: -0.063rem;

  @media ${tablet} {
    font-size: 1.25rem;
    letter-spacing: -0.02rem;
  }
`;

const Subtitle = styled.p`
  color: #28293d;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
  letter-spacing: -0.016rem;
  padding-top: 0.75rem;
  span {
    color: #2fbe8f;
    font-weight: 700;
  }
  b {
    font-weight: 700;
  }
`;

const StyledImg = styled(DynamicImage)`
  margin: 1.5rem 0;
`;

const StyledButton = styled(PrimaryButton)`
  border-radius: 6.25rem;
  background: #804da1;
  box-shadow: 0px 0px 1px 0px rgba(40, 41, 61, 0.04),
    0px 2px 4px 0px rgba(96, 97, 112, 0.16);
  width: 100%;
`;

const BottomText = styled.p`
  color: #28293d;
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -0.014rem;
  span {
    font-weight: 700;
  }
`;

const BottomSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  width: 100%;
`;

const Color = styled.section<{ withLC?: boolean }>`
  width: 1.34375rem;
  height: 3px;
  background: ${({ withLC }) => (withLC ? '#2fbe8f' : '#EC594D')};
`;

const Bottom = styled.section`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  margin-bottom: 1.5rem;
`;
